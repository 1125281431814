/**
 * Do not edit directly
 * Generated on Thu, 03 Apr 2025 06:37:19 GMT
 */

export const colorBrandprimary = "#4f41aaff";
export const colorBrandprimaryhover = "#776bc7ff";
export const colorBrandprimarypressed = "#372d76ff";
export const colorBrandprimarydisabled = "#999999ff";
export const colorBodynegative = "#ffffffff";
export const colorBody = "#221c4aff";
export const colorGray80 = "#4e496eff";
export const colorGray60 = "#7b7792ff";
export const colorGray40 = "#a7a4b7ff";
export const colorGray20 = "#d3d2dbff";
export const colorGray05 = "#f4f4f6ff";
export const colorPagebg = "#ffffffff";
export const colorDarkModeBody = "#ffffffff";
export const colorDarkModeGray80 = "#d3d2dbff";
export const colorDarkModeGray60 = "#a7a4b7ff";
export const colorDarkModeGray40 = "#7b7792ff";
export const colorDarkModeGray20 = "#4e496eff";
export const colorDarkModeGray05 = "#2d2753ff";
export const colorDarkModePagebg = "#221c4aff";
export const colorDividerBorder = "#DADEE3";
export const colorDividerLgBorder = "#CCCCCC";
export const colorButtonDefault = "#FFFFFF";
export const colorButtonPrimary = "#5041AB";
export const colorButtonSecondary = "#858C94";
export const colorButtonSuccess = "#52D869";
export const colorButtonDark = "#09101D";
export const colorButtonLight = "#F9F9F9";
export const colorButtonGray10 = "#9098A1";
export const colorSpinnerDefault = "#FFFFFF";
export const colorSpinnerDark = "#000000";
export const colorSpinnerCircular = "#372D76";
export const colorIconPrimary = "#5041AB";
export const colorIconSecondary = "#6E6E6E";
export const colorIconInfo = "#097EEA";
export const colorIconWarning = "#EAA800";
export const colorIconSuccess = "#96C568";
export const colorIconDanger = "#FF8126";
export const colorIconExtra = "#04B8B8";
export const colorTextPrimary = "#5041AB";
export const colorTextSecondary = "#09101D";
export const colorTextInfo = "#858788";
export const colorTextMuted = "#6D7580";
export const colorTextDark = "#372D76";
export const colorTextDanger = "#DB3131";
export const colorTextUnique = "#3D399F";
export const colorTextDefault = "#FFFFFF";
export const colorPrimary = "#5041AB";
export const colorSecondary = "#202123";
export const colorInfo = "#202424";
export const colorSuccess = "#DADEE3";
export const colorWarning = "#F9F9F9";
export const colorDanger = "#C7C7C7";
export const colorLight = "#535556";
export const colorFlesh = "#F4F6F9";
export const colorMessage = "#3E9D86";
export const colorDark = "#3C3D3F";
export const colorExtra = "#F2B1B7";
export const colorSpecial = "#FBADB8";
export const colorUnique = "#12171E";
export const colorMuted = "#6D7580";
export const colorDefault = "#FFFFFF";